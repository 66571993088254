import SiteButton_CircleButtonComponent from '@wix/thunderbolt-elements/src/components/SiteButton/viewer/skinComps/BaseButton/CircleButton.skin';
import SiteButton_CircleButtonController from '@wix/thunderbolt-elements/src/components/SiteButton/viewer/SiteButton.controller';


const SiteButton_CircleButton = {
  component: SiteButton_CircleButtonComponent,
  controller: SiteButton_CircleButtonController
};


export const components = {
  ['SiteButton_CircleButton']: SiteButton_CircleButton
};

